import { useEffect, useState } from "react"
import { VscArrowUp } from "react-icons/vsc"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getAllCustomers} from "../../../services/operations/customerDetailsAPI"
import IconBtn from "../../Common/IconBtn"
import CustomerTable from "./AdminCustomers/CustomerTable"
import UserBillingDetails from "../../Common/userBillingDetails"
import { verifyPaymentByAdmin  } from "../../../services/operations/studentFeaturesAPI"
export default function Customers() {
  const { token } = useSelector((state) => state.auth)
  const { user } = useSelector((state) => state.profile)
  const navigate = useNavigate()
  const [customers, setCustomers] = useState([])
  const [customerType, setCustomerType] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [clients, setClients] = useState(0);
  const [billingDetailsModal, setBillingDetailsModal] = useState(false);
  const [customerUser, setCustomerUser] = useState({})
  const [razorpayData, setRazorpayData] = useState({
    razorpay_signature:'',
    razorpay_payment_id:'',
    razorpay_order_id:'',
    userId:'',
    courses: ''
  });
  const fetchCourses = async () => {
    const result = await getAllCustomers(token, {page, searchValue, customerType})
    if (result.totalCustomers) {
      setCustomers(result.result);
      setTotalCustomers(result.totalCustomers);
      setClients(result.clients);
      
    }
  }
  useEffect(() => {
    fetchCourses()
     
  }, [page])

  const handleOnChange = (e) => {
    let tempData = {[e.target.name]: e.target.value}
    setRazorpayData((prevData) => ({
      ...prevData,
      ...tempData
    }))
  }

  const handleManualEnroll =async (billingDetails)=>{
    let payload = JSON.parse(JSON.stringify(razorpayData));
    payload['courses'] = payload.courses.split(',');
    payload['billingDetails'] = billingDetails;
    console.log(payload, 'final payload')
    await verifyPaymentByAdmin(payload, token)
  }

  const billingDetailsSubmitted = (billing_details)=>{
    let billingDetails = ({...billing_details, address: {city: billing_details.city, state: billing_details.state, stateCode: billing_details.stateCode, street:billing_details.street}});
    setBillingDetailsModal(false);
    handleManualEnroll(billingDetails);
   } 

   const showBillingDetailsModal = (e)=>{
    e.preventDefault();
    if(
      !razorpayData.razorpay_signature ||
      !razorpayData.razorpay_payment_id ||
      !razorpayData.razorpay_order_id ||
      !razorpayData.userId ||
      !razorpayData.courses
    ){
      return;
    }
    let customerFind = false;
    customers.forEach((customer)=>{
      if(customer._id==razorpayData.userId){
        setCustomerUser(JSON.parse(JSON.stringify(customer)));
        customerFind = true;
      }
    })
    if(!customerFind) return;
    setBillingDetailsModal(true);
   }
   
  return (
    <div>
      {billingDetailsModal && <UserBillingDetails user={customerUser} onSubmit={billingDetailsSubmitted} setBillingDetailsModal={setBillingDetailsModal}/>}

      <div className="mb-14 flex items-center justify-between">
        <h1 className="text-3xl font-medium text-[#313131]">Customers ({clients} of {totalCustomers})</h1>

        <div>
          <input className="rounded-md text-center py-2 bg-[#dbb4ac] add-promo text-[#313131]" type="text" name="searchValue" placeholder="search by email" id="searchValue" value={searchValue} onChange={(e)=>setSearchValue(e.target.value)}/>
          <button className="rounded-[25px] border-2 border-[#dbb4ac]  px-[12px] py-[8px] text-[#313131] text-sm font-semibold hover:bg-[#dbb4ac7d]" onClick={()=>{fetchCourses()}}>Search</button>
        </div>
        <IconBtn
          text="Export to Google"
          onclick={() =>{} }
        >
          <VscArrowUp />
        </IconBtn>
      </div>
      {customers && <CustomerTable customers={customers} setCustomers={setCustomers} />}
      <div className="flex justify-between my-5"> 
        <p>
          Page {page} of  {Math.ceil(totalCustomers/20)}
        </p>
        <div className="flex gap-10">
          <button className="rounded-[25px] border-2 border-[#dbb4ac]  px-[12px] py-[8px] text-[#313131] text-sm font-semibold hover:bg-[#dbb4ac7d]" onClick={()=>{page>1 ? setPage(page-1): ''}}>Prev</button>
          <button className="rounded-[25px] border-2 border-[#dbb4ac]  px-[12px] py-[8px] text-[#313131] text-sm font-semibold hover:bg-[#dbb4ac7d]" onClick={()=>{page < Math.ceil(totalCustomers/20) ? setPage(page+1) :''}}>Next</button>
        </div>
      </div>
      <div>
        <h2>Manual Enrollment</h2>
        <form onSubmit={(e)=>showBillingDetailsModal(e)} className="flex w-full flex-col items-center gap-y-4 p-1 my-6 lg-page ">
        <div className="w-full flex gap-x-4">
          <label className="flex-1">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
              Razorpay paymentId <sup className="text-pink-200">*</sup>
            </p>
            <input
              required
              type="text"
              name="razorpay_payment_id"
              value={razorpayData.razorpay_payment_id}
              onChange={handleOnChange}
              placeholder="Enter Payment id"
              className="form-style w-full"
            />
          </label>
          <label className="flex-1">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
              Razorpay Order Id <sup className="text-pink-200">*</sup>
            </p>
            <input
              required
              type="text"
              name="razorpay_order_id"
              value={razorpayData.razorpay_order_id}
              onChange={handleOnChange}
              placeholder="Enter order id"
              className="form-style w-full"
            />
          </label>
        </div>
        <label className="w-full">
          <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
            User Id <sup className="text-pink-200">*</sup>
          </p>
          <input
            required
            type="text"
            name="userId"
            value={razorpayData.userId}
            onChange={handleOnChange}
            placeholder="Enter userID"
            className="form-style w-full"
            
          />
        </label>
        <div className="w-full flex gap-x-4">
          <label className="flex-1">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
              Course ID <sup className="text-pink-200">*</sup>
            </p>
            <input
              required
              type="text"
              name="courses"
              value={razorpayData.courses}
              onChange={handleOnChange}
              placeholder="Enter course id"
              className="form-style w-full"
            />
          </label>
          <label className="flex-1">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-[#313131]">
              Secret <sup className="text-pink-200">*</sup>
            </p>
            <input
              required
              type="text"
              name="razorpay_signature"
              value={razorpayData.razorpay_signature}
              onChange={handleOnChange}
              placeholder="Enter secret"
              className="form-style w-full"
              
            />
          </label>
          
        </div>
        <button
          type="submit"
          className=" flex-1 mt-3 rounded-[8px] bg-[#dbb4ac] py-[8px] px-[60px] font-medium text-richblack-900"
        >
          Proceed to Pay
        </button>
      </form>
      </div>
    </div>
  )
}
