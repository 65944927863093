import { toast } from "react-hot-toast"

import rzpLogo from "../../assets/Logo/rzp_logo.png"
import { resetCart } from "../../slices/cartSlice"
import { setPaymentLoading } from "../../slices/courseSlice"
import { apiConnector } from "../apiConnector"
import { studentEndpoints } from "../apis"
import environment from "../../environment"

const {
  COURSE_PAYMENT_API,
  COURSE_VERIFY_API,
  SEND_PAYMENT_SUCCESS_EMAIL_API,
  COUPON_VERIFY_API,
  INVOICE_NUMBER_API
} = studentEndpoints

// Load the Razorpay SDK from the CDN
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script")
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

// Buy the Course
export async function BuyCourse(
  token,
  courses,
  user_details,
  promo,
  billingDetails,
  navigate,
  dispatch
) {
  const toastId = toast.loading("Loading...")
  try {
    // Loading the script of Razorpay SDK
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")

    if (!res) {
      toast.error(
        "Razorpay SDK failed to load. Check your Internet Connection."
      )
      return
    }

    // Initiating the Order in Backend
    const orderResponse = await apiConnector(
      "POST",
      COURSE_PAYMENT_API,
      {
        courses,
        promo,
        billingDetails
      },
      {
        Authorization: `Bearer ${token}`,
      }
    )

    if (!orderResponse.data.success) {
      throw new Error(orderResponse.data.message)
    }
    console.log("PAYMENT RESPONSE FROM BACKEND............", orderResponse.data)

    // Opening the Razorpay SDK
    const options = {
      key: environment().razorpay_client_id,
      currency: orderResponse.data.data.currency,
      amount: `${orderResponse.data.data.amount}`,
      order_id: orderResponse.data.data.id,
      name: "Thapar Dog School",
      description: "Thank you for Purchasing the Course.",
      image: rzpLogo,
      prefill: {
        name: `${user_details.firstName} ${user_details.lastName}`,
        email: user_details.email,
        mobile: user_details.mobile
      },
      handler: function (response) {
        sendPaymentSuccessEmail(response, orderResponse.data.data.amount, token)
        verifyPayment({ ...response, courses, promo, billingDetails }, token, navigate, dispatch)
      },
    }
    const paymentObject = new window.Razorpay(options)
    // sendPaymentSuccessEmail({}, orderResponse.data.data.amount, token)
    // verifyPayment({  courses }, token, navigate, dispatch)
    paymentObject.open()
    paymentObject.on("payment.failed", function (response) {
      toast.error("Oops! Payment Failed.")
      console.log(response.error)
    })
  } catch (error) {
    console.log("PAYMENT API ERROR............", error)
    toast.error("Could Not make Payment.")
  }
  toast.dismiss(toastId)
}

// Verify the Payment
async function verifyPayment(bodyData, token, navigate, dispatch) {
  const toastId = toast.loading("Verifying Payment...")
  if(dispatch )dispatch(setPaymentLoading(true))
  try {
    const response = await apiConnector("POST", COURSE_VERIFY_API, bodyData, {
      Authorization: `Bearer ${token}`,
    })

    console.log("VERIFY PAYMENT RESPONSE FROM BACKEND............", response)

    if (!response.data.success) {
      throw new Error(response.data.message)
    }

    toast.success("Payment Successful. You are Added to the course ")
    if(navigate) navigate("/dashboard/enrolled-courses")
    if(dispatch) dispatch(resetCart())
  } catch (error) {
    console.log("PAYMENT VERIFY ERROR............", error)
    toast.error("Could Not Verify Payment.")
  }
  toast.dismiss(toastId)
  if(dispatch) dispatch(setPaymentLoading(false))
}

// Send the Payment Success Email
async function sendPaymentSuccessEmail(response, amount, token) {
  try {
    await apiConnector(
      "POST",
      SEND_PAYMENT_SUCCESS_EMAIL_API,
      {
        orderId: response?.razorpay_order_id || 'abc',
        paymentId: response?.razorpay_payment_id || "asds",
        amount,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    )
  } catch (error) {
    console.log("PAYMENT SUCCESS EMAIL ERROR............", error)
  }
}

export async function verifyCoupon(promo, courseId){
  try {
    let res = await apiConnector(
      "GET",
      COUPON_VERIFY_API + `?courseId=${courseId}&promo=${promo}`,  
    )
    if(!res.data.success){
      return toast.error('Invalid Promo Code')
    }

    toast.success('Promo applied.');
    return res.data.discount
  } catch (error) {
    toast.error('Invalid Promo Code')
    console.log("PAYMENT SUCCESS EMAIL ERROR............", error)
  }
}

export async function generateInvoiceNumber(token){
  try {
    let res = await apiConnector(
      "GET",
      INVOICE_NUMBER_API,  
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    )
    if(!res.data.success){
      return toast.error('Invoice number not generated.')
    }

    toast.success('Invoice Number Generated');
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 1000*120;
    now.setTime(expireTime);
    let domain = environment().env=='develop' ? 'localhost:3000' : 'thapardogschool.in'
    document.cookie = 'tds_invoice_number' + "=" + res.data.invoiceNumber + "; expires=" + now.toUTCString(); + "; domain=" + domain + "; SameSite=None; Secure; path=/";
    return res.data.invoiceNumber
  } catch (error) {
    toast.error('Invoice number not generated.')
    console.log("PAYMENT SUCCESS EMAIL ERROR............", error)
  }
}

export async function verifyPaymentByAdmin(bodyData, token, navigate, dispatch) {
  const toastId = toast.loading("Verifying Payment...")
  if(dispatch )dispatch(setPaymentLoading(true))
  try {
    const response = await apiConnector("POST", COURSE_VERIFY_API, bodyData, {
      Authorization: `Bearer ${token}`,
    })

    console.log("VERIFY PAYMENT RESPONSE FROM BACKEND............", response)

    if (!response.data.success) {
      throw new Error(response.data.message)
    }

    toast.success("Payment Successful. You are Added to the course ")
    if(navigate) navigate("/dashboard/enrolled-courses")
    if(dispatch) dispatch(resetCart())
  } catch (error) {
    console.log("PAYMENT VERIFY ERROR............", error)
    toast.error("Could Not Verify Payment.")
  }
  toast.dismiss(toastId)
  if(dispatch) dispatch(setPaymentLoading(false))
}